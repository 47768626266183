$root-size: 20px;
$responsive-min: 320px;
$responsive-max: 1065px;
$transition-time: 0.25s;
$col-order-break-at: 595px;

$color-primary: #db0011;

$color-white-900: #ffffff;
$color-white-875: #f1f3f4;
$color-white-850: #f0f0f0;
$color-white-800: #e1e0e1;
$color-white-810: #e6e6e6;
$color-white-775: #e5e5e5;
$color-white-750: #e7e9ec;
$color-white-600: #d1d1d1;
//$color-black-400: #464646;
$color-black-400: #000;
$color-black-175: #171723;
$color-black-800: 010000;
$color-black-150: #090909;
$color-black-900: #000000;

$grid-breakpoints: (
  xs: 0px,
  sm: 450px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$font-primary: "Univers Next for HSBC", sans-serif;
