@import "../../styles/mixins";

.app {
  width: 100%;
  height: 100%;

  &-browser--ie-11 {
    .carousel {
      overflow: hidden;
    }

    .carousel-items {
      height: auto !important;
    }

    .language-selector {
      scrollbar-base-color: $color-white-850;
      scrollbar-face-color: $color-primary;
      scrollbar-track-color: $color-white-850;
      scrollbar-arrow-color: $color-primary;
    }

    .scrollbar-container {
      scrollbar-base-color: #f7f7f7;
      scrollbar-face-color: $color-primary;
      scrollbar-track-color: #f7f7f7;
      scrollbar-arrow-color: $color-primary;
    }
  }
}
