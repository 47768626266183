$mobile-screen-width: 600px;
$tablet-screen-width: 768px;
@mixin mobile {
  @at-root .Screen--mobile & {
    @content;
  }

  @media (max-width: $mobile-screen-width) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-screen-width) {
    @content
  }
}

@mixin desktop {
  @media (min-width: $mobile-screen-width + 1) {
    @content
  }
}

.Animate {
  $animation-time: 1;

  @include desktop {
    opacity: 0;
    position: relative;
    transition: 0.5s all ease;
  }

  &--count-numbers {
    opacity: 0;
  }

  &--delay-cols {
    @for $i from 1 through 3 {
      .Animate:nth-child(#{$i + 1}) {
        animation-delay: #{0.2 * $i}s !important;
      }
    }
  }

  &--delay-elements {
    @for $i from 1 through 10 {
      .Animate:nth-child(#{$i + 1}) {
        animation-delay: #{0.1 * $i}s !important;
      }
    }
  }

  &--delay-elements-double {
    @for $i from 0 through 10 {
      div:nth-child(#{$i + 1}) {
        .Animate {
          animation-delay: #{0.4 + (0.1 * $i)}s !important;
        }
      }
    }
  }

  &--fade {
    @include desktop {
      &.Animate--show {
        animation: fade #{$animation-time}s forwards;
      }
    }
    @include mobile {
      &.Animate--show {
        animation: fade #{$animation-time}s forwards;
      }
    }
  }

  &--fade-up {
    @include desktop {
      &.Animate--show {
        animation: fade-up #{$animation-time}s forwards;
      }
    }
    @include mobile {
      &.Animate--show {
        animation: fade-up #{$animation-time}s forwards;
      }
    }
  }

  &--fade-down {
    @include desktop {
      &.Animate--show {
        animation: fade-down #{$animation-time}s forwards;
      }
    }
    @include mobile {
      &.Animate--show {
        animation: fade-down #{$animation-time}s forwards;
      }
    }
  }

  &--slide-to-right {
    @include desktop {
      &.Animate--show {
        animation: slide-to-right #{$animation-time}s forwards;
      }
    }
  }

  &--slide-to-left {
    @include desktop {
      &.Animate--show {
        animation: slide-to-left #{$animation-time}s forwards;
      }
    }
  }

  &--grow-to-180 {
    @include desktop {
      &.Animate--show {
        opacity: 1;
        width: 120%;
        transition: width 1.5s ease;
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-to-right {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-to-left {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  10%, 20%, 80%, 90% {
    transform: translate3d(0, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -5px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 5px, 0);
  }
}

.fade-in {
  animation: fade .75s ease;
}
