@import "vars";
@import "mixins";
@import "fonts";
@import "reset";

html {
  $font-size: $root-size;
  @include fluid-attr(font-size, $font-size * 0.75, $font-size);
  font-family: $font-primary;
  line-height: 1.2;
}


.sm-margin-top-bottom-2 {
  $min-margin: 1em;
  $max-margin: 2em;
  @include fluid-attr(margin-bottom, $min-margin, $max-margin);
  @include fluid-attr(margin-top, $min-margin, $max-margin);
  @media screen and (min-width: $col-order-break-at) {
    margin-bottom: 1em;
    margin-top: 0;
  }
}

.brush-paint-background {
  background-image: url('/assets/red-paint.png');
  background-repeat: no-repeat;
  width: 100%;
  padding: .5em;
  background-position: center;
  background-size: contain;
  color: $color-white-900;

  &.type-1 {
    padding-top: .3em;
    padding-right: 1em;
    padding-bottom: .6em;
  }

  &.type-2 {
    padding: .2em .2em .5em;
  }
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}


.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}
strong {
  font-weight: bold;
}
.w-md-45 {
  width: 45% !important;
}
.w-md-55 {
  width: 55% !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.w-100 {
  width: 100% !important;
}
.ml-30px {
  margin-left: 30px !important;
}
.mb-25px {
  margin-bottom: 25px
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-30px {
  margin-top: 30px !important;
}
.mt-50px {
  margin-top: 50px !important;
}
.red {
  color: $color-primary !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mw-md-400px {
  @media screen and (min-width: 720px){
      max-width: 400px !important;
  }
}
.mw-md-550px {
  @media screen and (min-width: 720px){
    max-width: 550px !important;
  }
}
.mt-md-negative-100 {
  @media screen and (min-width: 720px){
    margin-top: -100px !important;
  }
}
.mt-mobile-1rem {
  @media screen and (max-width: 719px){
    margin-top: 1rem;
  }
}
.d-block {
  @media screen and (max-width: 719px){
    display: block;
  }
}
.d-md-none {
  @media screen and (min-width: 720px){
    display: none;
  }
}
.title--weight-300 {
  h3 {
    font-weight: 300;
  }
}
hr.primary {
  border: 1px solid red
}
.paragraph--type-2 {
  font-size: 24px;
  font-weight: 600;
}
.paragraph--type-3 {
  font-weight: 300;
  margin-bottom: 25px;
}
.paragraph--type-4 {
  font-size: 24px;
  font-weight: 100;
}
.red {
  color: red;
}
.list-type-2 {
  margin: 0;

  strong {
    font-weight: 500;
  }
  li {
    font-weight: 300;
  }
}
.font-weight-300 {
  font-weight: 300 !important;
}
.bt-1 {
  border-top: 1px solid transparent;
}
.carousel-nav {
  .carousel-nav-frame:nth-child(2) {
    margin-right: 11px;
  }
}
.grid-col--overflow-hidden {
  overflow: hidden;
}
